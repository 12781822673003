(function(){
    'use strict';

    angular.module('aerosApp')
        .component('aflModalCarouselDatetimeInput', {
            templateUrl: '/static/javascript/directives/afl-modal-carousel/afl-datetime-input/afl-modal-carousel-datetime-input.html',
            controller: aflDatetimeInputController,
            bindings: {
                group: '=',
                editable: '=',
                fieldDescription: '=',
                model: '=',
                mode: '=',
                fieldName: '=',
                fullDotName: '=',
                required: '=',
                visible: '=',
                fieldChangeEventHandler: '&',
                parent: '='
            }
        });

    function aflDatetimeInputController() {
        var $ctrl = this;

        $ctrl.setVisibility = function setVisibility() {
            if (typeof $ctrl.visible !== "undefined") {
                $ctrl.isVisible = !!$ctrl.visible;
            } else {
                $ctrl.isVisible = $ctrl.fieldDescription.type !== 'hidden'
            }
        };

        (function init(){
            $ctrl.setVisibility();
        })();
    }
})();
